import {getRegion, getStage} from "../utils/Network/BaseURLUtils";
import {Constants} from "@amzn/dolphin-web-framework";

export const LANGUAGE = {
    UK: 'uk',
    NA: 'na',
    DE: 'de',
    FR: 'fr',
    IT: 'it',
    ES: 'es',
    JP: 'jp',
    MX: 'mx',
    NL: 'nl',
    CA_FR: 'ca-fr',
    IN: 'in',
    BR: 'br',
    TR: 'tr',
    RO: 'ro',
    AR: 'ar'
};


export function getLoggerUrl() {
    // Using url from DolphinDronaLibWebsite for logging.
    switch (getStage()) {
        case Constants.Stage.BETA:
            return "https://o9flu8ivwa.execute-api.us-west-2.amazonaws.com/prod/v1/log";
        case Constants.Stage.GAMMA:
            switch (getRegion()) {
                case Constants.Region.REGION_NA:
                    return "https://cdmva263k2.execute-api.us-east-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_EU:
                    return "https://ldlq367y4l.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_FE:
                    return "https://e8ckamhn3k.execute-api.us-west-2.amazonaws.com/prod/v1/log";
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch (getRegion()) {
                case Constants.Region.REGION_NA:
                    return "https://9vhjlsfdng.execute-api.us-east-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_EU:
                    return "https://06i0v576sf.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_FE:
                    return "https://ykgu1836fa.execute-api.us-west-2.amazonaws.com/prod/v1/log";
                // no default
            }
            break;
        // no default
    }
}

export const ROUTE_PATH = {
    STATIC_TRAINING_PATH: "/",
    IAT_LITE_TRAINING: "/iat",
    HELP_CENTRE: "/help",
    LANGUAGE_SELECTION: "/iatLanguage",
    TRAINING_SELECTION: "/iatTraining",
    OLD_TRAINING_SELECTION: "/oldIatTraining",
    QR_SCANNING_SELECTION: "/scan"
}

export const TRAINING_CONSTANTS = {
    TRAINING: "Training",
    SELECT_TRAINING: "Select a Training",
    HELP_CENTRE: "Help Center",
    SELECT_LANGUAGE: "Select a Language",
    SCAN_QR: "Scan QR Code"
}

