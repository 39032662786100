import { UAASAccessor } from "@amzn/dolphin-web-framework";
import {getStage, getRegion} from "./Network/BaseURLUtils";

export default {
    async validateMAPToken() {
        const stage = getStage();
        const region = getRegion();
        let isMAPTokenValid = false;
        let modulesAndFeatures = null;
        
        try {
            modulesAndFeatures = await UAASAccessor.getModulesAndFeatures(stage, region);
        } catch (error) {
            console.error("Failed to call UAAS API getModulesAndFeatures", error.stack);
        }
        
        // As UAASAccessor will apply MAP token for each API call and UAAS will validate MAP token in the request, 
        // if UAAS returns proper response, it means the MAP token is valid
        if (Array.isArray(modulesAndFeatures?.features) && modulesAndFeatures.features.length > 0) {
            isMAPTokenValid = true;
        }
        
        return isMAPTokenValid;
    }
}