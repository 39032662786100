import React, {useState} from "react";
import Column from "@amzn/meridian/column";
import Training from "./S3Trainings";
import {TaskView, TopNavigationView} from "@amzn/dolphin-uiweb-framework";
import {ROUTE_PATH, TRAINING_CONSTANTS} from "./constants/Constants";
import {PLAYER_CONSTANTS} from "./constants/PlayerConstants";
import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import {getRegion} from "./utils/Network/BaseURLUtils";
import {injectIntl} from "react-intl";
import addQueryParamsToTrainingURL from "./utils/QueryStringUtils";

const IATLiteWithS3TrainingView = (props) => {

    const [trainings] = useState(() => {
        const regionData = Training[getRegion()];
        const countryCode = localStorage[PLAYER_CONSTANTS.COUNTRY];
        const stationCode = localStorage[PLAYER_CONSTANTS.STATION_CODE];
        const countryData = regionData[countryCode] || regionData[PLAYER_CONSTANTS.DEFAULT];
        return countryData[stationCode] || countryData[PLAYER_CONSTANTS.DEFAULT];
    });

    const playTraining = (trainingUrl) => {
        if(trainingUrl) {
            return window.location.href = addQueryParamsToTrainingURL(trainingUrl);
        }
    }

    const tasks = trainings.map((value) => {
        return {title: value.processName,
            description: value.description || value.processName,
            onClick: () => {playTraining(value.trainingUrl)}}
    });


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onBackPressed = React.useCallback(() => {
        props.history.goBack();
    });

    return (
        <Column height="100%" spacing="none">
            <Column spacing="small">
                {/* Top Navigation Bar */}
                <TopNavigationView title={TRAINING_CONSTANTS.TRAINING} backgroundType={PLAYER_CONSTANTS.DEFAULT}
                                   onMenuButtonClicked={onBackPressed}
                                   optionsMenu={[{icon: helpTokens,onClick: () => {props.history.push(ROUTE_PATH.HELP_CENTRE);}}]}/>
            </Column>
            <div className="content">
                <Column spacing="none">
                    <TaskView pageTitle={TRAINING_CONSTANTS.SELECT_TRAINING} tasks={tasks} />
                </Column>
            </div>
        </Column>
    )
}

export default injectIntl(IATLiteWithS3TrainingView);