import React, {useState} from "react";
import Training from "./training.json";
import {PLAYER_CONSTANTS} from "./constants/PlayerConstants";
import {getRegion} from "./utils/Network/BaseURLUtils";
import "@amzn/dolphin-uiweb-framework/dist/index.css";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {TaskView, TopNavigationView} from "@amzn/dolphin-uiweb-framework";
import Column from "@amzn/meridian/column";
import helpTokens from "@amzn/meridian-tokens/base/icon/help"
import {ROUTE_PATH, TRAINING_CONSTANTS} from "./constants/Constants";
import {injectIntl} from "react-intl";
import playTraining from "./utils/PlayTraining";

const IATLiteTrainingView = (props) => {

    const [trainings] = useState(() => {
        const regionData = Training[getRegion()];
        const countryCode = localStorage[PLAYER_CONSTANTS.COUNTRY];
        const stationCode = localStorage[PLAYER_CONSTANTS.STATION_CODE];
        const countryData = regionData[countryCode] || regionData[PLAYER_CONSTANTS.DEFAULT];
        return countryData[stationCode] || countryData[PLAYER_CONSTANTS.DEFAULT];
    });

    const tasks = trainings.map((value) => {
        return {title: value.processName,
            description: value.description || value.processName,
            onClick: () => {playTraining(value.processId, value.trainingUrl, props)}}
    });

    return (
        <Column height="100%" spacing="none">
            <Column spacing="small">
                {/* Top Navigation Bar */}
                <TopNavigationView title={TRAINING_CONSTANTS.TRAINING} backgroundType={PLAYER_CONSTANTS.DEFAULT}
                                   onMenuButtonClicked={NativeMeshInteractor.exitModule}
                                   optionsMenu={[{icon: helpTokens,onClick: () => {props.history.push(ROUTE_PATH.HELP_CENTRE);}}]}/>
            </Column>
            <div className="content">
                <Column spacing="none">
                    <TaskView pageTitle={TRAINING_CONSTANTS.SELECT_TRAINING} tasks={tasks} />
                </Column>
            </div>
        </Column>
    )
}

export default injectIntl(IATLiteTrainingView);