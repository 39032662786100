
export function getRegion() {
    const hostname = window.location.hostname;
    if (hostname.startsWith('na')) return 'NA';
    else if (hostname.startsWith('eu')) return 'EU';
    else if (hostname.startsWith('fe')) return 'FE';
    //Added EU as default as CName for eu prod is not prefixed as eu.prod
    return 'EU';
}

export function getStage() {
    const hostname = window.location.hostname.split('.');
    if (hostname[1] != null) {
        const stage = hostname[1];
        if (stage.endsWith('integ')) return 'beta';
        else if (stage.endsWith('master')) return 'gamma';
        else if (stage.endsWith('prod')) return 'prod';
        //Added EU as default as CName for eu prod is not prefixed as eu.prod
        return 'prod';
    }
    return 'prod';
}