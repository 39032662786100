import React, { useState } from "react";
import { ROUTE_PATH, TRAINING_CONSTANTS } from "./constants/Constants";
import QR from "./assets/images/ic_qr_code.png";
import playTraining from "./utils/PlayTraining";
import {
  ScanWithCodeView,
  TopNavigationView,
} from "@amzn/dolphin-uiweb-framework";
import { FormattedMessage, injectIntl } from "react-intl";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import error_beep from "./assets/sounds/error_beep.mp3";
import sync_beep from "./assets/sounds/sync_beep.mp3";
const ScanQRView = (props) => {
  const [formError, setFormError] = useState(false);
  const [assignedError, setAssignedError] = useState(false);
  async function onSubmit(e) {
    //Input is in the form processId:process
    if (e.includes("processId:")) {
      let audio = new Audio(sync_beep);
      await audio.play();
      playTraining(e.substring(e.indexOf(":") + 1), null, props);
      //Reset the input after scan (In the case playTraining does not display new window)
      e = "";
      setAssignedError(true);
      setFormError(false);
    }
    //Input is in the form trainingUrl:link
    else if (e.includes("trainingUrl:")) {
      let audio = new Audio(sync_beep);
      await audio.play();
      playTraining(null, e.substring(e.indexOf(":") + 1), props);
      //Reset the input after scan (In the case playTraining does not display new window)
      e = "";
    }
    //Produces Error Screen if QR code produces invalid form of string
    else {
      let audio = new Audio(error_beep);
      await audio.play();
      setFormError(true);
      setAssignedError(false);
    }
  }

  const errorDialogue = formError
    ? "Invalid QR Code Provided"
    : assignedError
    ? "Training not assigned"
    : null;

  if (formError || assignedError) {
    return (
      <>
        <Column spacing="small">
          <TopNavigationView
            title={TRAINING_CONSTANTS.SCAN_QR}
            backgroundType={"error"}
            onMenuButtonClicked={() =>
              props.history.push(ROUTE_PATH.IAT_LITE_TRAINING)
            }
            optionsMenu={[]}
          />
        </Column>
        <Alert type="error" size="xlarge">
          <FormattedMessage
            id={"Scan.error"}
            defaultMessage={errorDialogue.toString()}
          ></FormattedMessage>
        </Alert>
        <ScanWithCodeView onSubmit={onSubmit} focusLock={true} />
      </>
    );
  } else {
    return (
      <>
        <Column spacing="small">
          <TopNavigationView
            title={TRAINING_CONSTANTS.SCAN_QR}
            backgroundType={"default"}
            onMenuButtonClicked={() =>
              props.history.push(ROUTE_PATH.IAT_LITE_TRAINING)
            }
            optionsMenu={[]}
          />
        </Column>
        <ScanWithCodeView
          primaryTitle={{
            title: TRAINING_CONSTANTS.SCAN_QR,
            size: "Large",
            type: "Bold",
          }}
          image={QR}
          onSubmit={onSubmit}
          focusLock={true}
        />
      </>
    );
  }
};
export default injectIntl(ScanQRView);
