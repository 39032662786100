import React from "react";
import Column from "@amzn/meridian/column";
import {TaskView, TopNavigationView} from "@amzn/dolphin-uiweb-framework";
import {ROUTE_PATH, TRAINING_CONSTANTS} from "./constants/Constants";
import {PLAYER_CONSTANTS} from "./constants/PlayerConstants";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import {injectIntl} from "react-intl";


const LanguageSelectionView = (props) => {

    const languages = [
        {
            "title" : "Bengali",
            "locale" : "bn"
        },
        {
            "title" : "English",
            "locale" : "en"
        },
        {
            "title" : "Gujarati",
            "locale" : "gu"
        },
        {
            "title" : "Hindi",
            "locale" : "hi"
        },
        {
            "title" : "Kannada",
            "locale" : "kn"
        },
        {
            "title" : "Malayalam",
            "locale" : "ml"
        },
        {
            "title" : "Marathi",
            "locale" : "mr"
        },
        {
            "title" : "Tamil",
            "locale" : "ta"
        },
        {
            "title" : "Telugu",
            "locale" : "te"
        },
        ];

    const onButtonClick = (value) => {
        localStorage[PLAYER_CONSTANTS.LOCALE] = value.locale;
        props.history.push(ROUTE_PATH.TRAINING_SELECTION);
    }

    const tasks = languages.map((value) => {
        return {title: value.title,
            onClick: () => {onButtonClick(value)}}
    });

    return (
        <Column height="100%" spacing="none">
            <Column spacing="small">
                {/* Top Navigation Bar */}
                <TopNavigationView title={TRAINING_CONSTANTS.TRAINING} backgroundType={PLAYER_CONSTANTS.DEFAULT}
                                   onMenuButtonClicked={NativeMeshInteractor.exitModule}
                                   optionsMenu={[{icon: helpTokens,onClick: () => {props.history.push(ROUTE_PATH.HELP_CENTRE);}}]}/>
            </Column>
            <div className="content">
                <Column spacing="none">
                    <TaskView pageTitle={TRAINING_CONSTANTS.SELECT_LANGUAGE} tasks={tasks} />
                </Column>
            </div>
        </Column>
    )
}

export default injectIntl(LanguageSelectionView);