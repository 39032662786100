import "@amzn/dolphin-uiweb-framework/dist/index.css";
import {PLAYER_CONSTANTS, PLAYER_LIB_REGION_BASED_URL, PLAYER_VERSION} from "../constants/PlayerConstants";
import {ROUTE_PATH} from "../constants/Constants";
import {getRegion, getStage} from "./Network/BaseURLUtils";
import loadExternal from "./LoadExternal";
import "@amzn/dolphin-uiweb-framework/dist/index.css";
import {AuthTokenProvider, Logger, PlayerHelper} from "@amzn/dolphin-web-framework";
import addQueryParamsToTrainingURL from "./QueryStringUtils";
import { BearerTokenUtils } from "@amzn/dolphin-web-framework";
import AuthUtils from './AuthUtils'

async function playTraining(processId, trainingUrl, props = NaN) {

    // validate if the current token is valid
    const isAccessTokenValid = BearerTokenUtils.accessToken && !BearerTokenUtils.isAccessTokenExpired();

    if (!isAccessTokenValid) {
        console.log("Access token is invalid, trying to refresh the MAP token")
        // If access token is invalid or expired, we will conduct one more try to refresh + validate the token
        // Specifically, AuthUtils.validateMAPToken() will use UAASAccessor to call UAAS. Under the hood, UAASAccessor is a wrapper of dolphinAPIClient. 
        // Before making UAAS call, dolphinAPIClient will refresh the token and UAAS will validate the token
        const isMAPTokenValid = await AuthUtils.validateMAPToken();

        if (!isMAPTokenValid) {
            console.log("Failed to validate MAP token. Set IsAuthenticatedState to false")
            props.updateIsAuthenticatedState(false)
            return;
        }
    }

    if (processId){
        const contextToLog = {
            userId: localStorage[PLAYER_CONSTANTS.USER_ID].toString(),
            stationCode: localStorage[PLAYER_CONSTANTS.STATION_CODE].toString(),
            training: processId.toString()
        }

        //Terminate Player if already initialised
        PlayerHelper.terminate().then(() => {
            Logger.log.warn("Player terminated successfully", contextToLog);
        });

        const URL = PLAYER_LIB_REGION_BASED_URL[getStage()][getRegion()] + PLAYER_VERSION + "/main.js";
        loadExternal(URL, (status) => {
            if (status) {
                if (window.DolphinPlayer) {
                    window.DolphinPlayer.initialize(processId, getStage(), getRegion(),
                        localStorage[PLAYER_CONSTANTS.EMPLOYEE_ID], localStorage[PLAYER_CONSTANTS.USER_ID],
                        localStorage[PLAYER_CONSTANTS.LOCALE], localStorage[PLAYER_CONSTANTS.STATION_CODE], new AuthTokenProvider());

                    Logger.log.warn("Player initialised", contextToLog);
                } else {
                    Logger.log.error('Player not found', contextToLog);
                }
            } else {
                Logger.log.warn('DRONA PLAYER not initialized, unable to download library', contextToLog);
            }
        });
    } else if (trainingUrl) {
        if(trainingUrl === ROUTE_PATH.OLD_TRAINING_SELECTION) {
            props.history.push(ROUTE_PATH.OLD_TRAINING_SELECTION);
        }
        else if (trainingUrl === ROUTE_PATH.QR_SCANNING_SELECTION){
            props.history.push(ROUTE_PATH.QR_SCANNING_SELECTION);
        }
        else {
            return window.location.href = addQueryParamsToTrainingURL(trainingUrl);
        }
    }
}

export default playTraining;