import React from "react";
import {LANGUAGE} from "./constants/Constants";

const StaticTrainingView = (props) => {

    const setLanguage = (language) => {
        localStorage['lang'] = language;
        switch (language) {
            case LANGUAGE.JP: window.location.href = 'new_device/jp.html'; break;
            case LANGUAGE.MX: window.location.href = 'new_device/mx.html'; break;
            case LANGUAGE.NL: window.location.href = 'new_device/nl.html'; break;
            case LANGUAGE.CA_FR: window.location.href = 'new_device/ca-fr.html';break;
            case LANGUAGE.BR: window.location.href = 'new_device/br.html';break;
            case LANGUAGE.IN: window.location.href = 'language.html';break;
            case LANGUAGE.ES: window.location.href = 'new_device/es.html'; break;
            case LANGUAGE.FR: window.location.href = 'new_device/fr.html'; break;
            case LANGUAGE.IT: window.location.href = 'new_device/it.html'; break;
            case LANGUAGE.DE: window.location.href = 'new_device/de.html'; break;
            case LANGUAGE.UK: window.location.href = 'new_device/uk.html'; break;
            case LANGUAGE.AR: window.location.href = 'new_device/ar.html'; break;
            case LANGUAGE.RO: window.location.href = 'new_device/ro.html'; break;
            case LANGUAGE.TR: window.location.href = 'new_device/tr.html'; break;
            default:   window.location.href = 'device.html';
        }
    }

    return (
        <div className="content">
            <button onClick={() => { setLanguage(LANGUAGE.UK) }} type="button" className="button">English (UK)</button>
            <button onClick={() => { setLanguage(LANGUAGE.NA) }} type="button" className="button">English (US)</button>
            <button onClick={() => { setLanguage(LANGUAGE.DE) }} type="button" className="button">Deutsch</button>
            <button onClick={() => { setLanguage(LANGUAGE.FR) }} type="button" className="button">Francais</button>
            <button onClick={() => { setLanguage(LANGUAGE.IT) }} type="button" className="button">Italiana</button>
            <button onClick={() => { setLanguage(LANGUAGE.ES) }} type="button" className="button">Espanola</button>
            <button onClick={() => { setLanguage(LANGUAGE.JP) }} type="button" className="button">日本語</button>
            <button onClick={() => { setLanguage(LANGUAGE.MX) }} type="button" className="button">Mexico</button>
            <button onClick={() => { setLanguage(LANGUAGE.NL) }} type="button" className="button">Dutch</button>
            <button onClick={() => { setLanguage(LANGUAGE.IN) }} type="button" className="button">India</button>
            <button onClick={() => { setLanguage(LANGUAGE.CA_FR) }} type="button" className="button">Québec</button>
            <button onClick={() => { setLanguage(LANGUAGE.BR) }} type="button" className="button">Brasil</button>
            <button onClick={() => { setLanguage(LANGUAGE.AR) }} type="button" className="button">عربي</button>
            <button onClick={() => { setLanguage(LANGUAGE.RO) }} type="button" className="button">Romanian</button>
            <button onClick={() => { setLanguage(LANGUAGE.TR) }} type="button" className="button">Turkish</button>
        </div>
    )
}

export default StaticTrainingView;