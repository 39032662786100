import {PLAYER_CONSTANTS} from "../constants/PlayerConstants";

const STRING_EMPTY = "";
const addQueryParamsToTrainingURL = (trainingURL) => {
    if(trainingURL !== null && trainingURL !== undefined) {
        const queryParams = constructQueryParams();
        return trainingURL.concat('?', queryParams.toString());
    }
    else return STRING_EMPTY;
}

const constructQueryParams = () => {
    return new URLSearchParams({
        locale: localStorage[PLAYER_CONSTANTS.LOCALE] ?? STRING_EMPTY,
        stationCode: localStorage[PLAYER_CONSTANTS.STATION_CODE] ?? STRING_EMPTY,
        userId: localStorage[PLAYER_CONSTANTS.USER_ID] ?? STRING_EMPTY,
    });
}

export default addQueryParamsToTrainingURL;