import React, {Component} from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import StaticTrainingView from "./StaticTrainingView";
import IATLiteTrainingView from "./IATLiteTrainingView";
import LanguageSelectionView from "./LanguageSelectionView";
import {MeshClient, PlayerHelper, DataHandler} from "@amzn/dolphin-web-framework";
import AuthUtils from './utils/AuthUtils'
import {HelpView} from "@amzn/dolphin-uiweb-framework";
import HELP_ICON from "./assets/images/ic_help_coming_up.png";
import {ROUTE_PATH, TRAINING_CONSTANTS} from "./constants/Constants";
import {PLAYER_CONSTANTS} from "./constants/PlayerConstants";
import IATLiteWithS3TrainingView from "./IATLiteWithS3TrainingView";
import ScanQRView from "./ScanQRView";
import LoaderView from "./LoaderView";
import TokenValidationErrorView from "./TokenValidationErrorView";


class App extends Component {

    constructor() {
        super()
        this.state = {
            isAuthenticated: null
        };
        this.updateIsAuthenticatedState = this.updateIsAuthenticatedState.bind(this)
    }

    updateIsAuthenticatedState(authState) {
        console.log(`updateIsAuthenticatedState - update status to: ${authState}`)
        this.setState({isAuthenticated: authState});
    }

    async componentWillMount() {
        await MeshClient.connectWebSocket();
        DataHandler.parseSearchParams();
        
        // Validating MAP token by calling UAAS
        const isMAPTokenValid = await AuthUtils.validateMAPToken();
        this.setState({isAuthenticated : isMAPTokenValid})
    }

    componentWillUnmount() {
        MeshClient.closeWebSocketConnection();
        PlayerHelper.terminate();
    }



    render() {
        // When isAuthenticated is null (i.e. initial value), it means UAAS call is not completed yet so displaying loading view
        if (this.state.isAuthenticated === null) {
            console.log("API call to UAAS is not completed yet so displaying loading view");
            return (
                (<LoaderView {...this.props} />)
            )
        }

        // When isAuthenticated is false, it means that it failed to validate MAP token therefore display error view
        if (this.state.isAuthenticated === false) {
            console.log("Failed to validate MAP token so displaying error view");
            return (
                <TokenValidationErrorView {...this.props} />
            )
        }
        
        return (
            <Switch>
                <Route exact path={ROUTE_PATH.STATIC_TRAINING_PATH} component={StaticTrainingView}/>
                <Route exact path={ROUTE_PATH.IAT_LITE_TRAINING} render={() => {
                    return (
                        (localStorage[PLAYER_CONSTANTS.COUNTRY] === 'IN') ?
                            <Redirect to={ROUTE_PATH.LANGUAGE_SELECTION}/> :
                            <Redirect to={ROUTE_PATH.TRAINING_SELECTION}/>
                    )
                }}/>
                <Route exact path={ROUTE_PATH.TRAINING_SELECTION} render={() => {
                    return (
                        <IATLiteTrainingView {...this.props} updateIsAuthenticatedState= {this.updateIsAuthenticatedState}/>
                    )
                }}/>
                <Route exact path={ROUTE_PATH.LANGUAGE_SELECTION} component={LanguageSelectionView}/>
                <Route exact path={ROUTE_PATH.OLD_TRAINING_SELECTION} component={IATLiteWithS3TrainingView}/>
                <Route exact path={ROUTE_PATH.HELP_CENTRE} >
                    <HelpView onClose={() => this.props.history.goBack()}
                              title={TRAINING_CONSTANTS.HELP_CENTRE}
                              help_icon={HELP_ICON}/>
                </Route>
                <Route exact path={ROUTE_PATH.QR_SCANNING_SELECTION} render={() => {
                    return (
                        <ScanQRView {...this.props} updateIsAuthenticatedState= {this.updateIsAuthenticatedState}/>
                    )
                }}/>
            </Switch>
        );
    }
}
export default withRouter(App);