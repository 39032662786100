import React from "react";
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import App from "./App";
import {Logger} from "@amzn/dolphin-web-framework";
import {getStage} from "./utils/Network/BaseURLUtils";
import {getLoggerUrl} from "./constants/Constants";
import './index.css';
import {addLocaleData, IntlProvider} from "react-intl";
import de from 'react-intl/locale-data/de'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'
import hi from 'react-intl/locale-data/hi'
import it from 'react-intl/locale-data/it'
import ja from 'react-intl/locale-data/ja'
import pt from 'react-intl/locale-data/pt'
import ro from 'react-intl/locale-data/ro'
import ar from 'react-intl/locale-data/ar'
import nl from 'react-intl/locale-data/nl'
import tr from 'react-intl/locale-data/tr'
import {getLocale, getMessages} from "./il8n/handler/locale-manager";

Logger.setLogger(getLoggerUrl(), getStage());
const params = new URLSearchParams(window.location.search);
for (let value of params.keys()) {
    localStorage[value] = params.get(value);
}

addLocaleData(de);
addLocaleData(en);
addLocaleData(es);
addLocaleData(fr);
addLocaleData(hi);
addLocaleData(it);
addLocaleData(ja);
addLocaleData(pt);
addLocaleData(ro);
addLocaleData(ar);
addLocaleData(nl);
addLocaleData(tr);


ReactDOM.render((
    <IntlProvider locale={getLocale()} messages={getMessages()}>
        <HashRouter>
            <App/>
        </HashRouter>
    </IntlProvider>

), document.getElementById('root'));
