import React from "react";
import {PLAYER_CONSTANTS} from "./constants/PlayerConstants";
import "@amzn/dolphin-uiweb-framework/dist/index.css";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {TopNavigationView} from "@amzn/dolphin-uiweb-framework";
import Column from "@amzn/meridian/column";
import helpTokens from "@amzn/meridian-tokens/base/icon/help"
import {ROUTE_PATH, TRAINING_CONSTANTS} from "./constants/Constants";
import {injectIntl} from "react-intl";
import Alert from "@amzn/meridian/alert";

const TokenValidationErrorView = (props) => {
    return (
        <Column height="100%" spacing="none">
            <Column spacing="small">
                {/* Top Navigation Bar */}
                <TopNavigationView title={TRAINING_CONSTANTS.TRAINING} backgroundType={PLAYER_CONSTANTS.DEFAULT}
                                   onMenuButtonClicked={NativeMeshInteractor.exitModule}
                                   optionsMenu={[{icon: helpTokens,onClick: () => {props.history.push(ROUTE_PATH.HELP_CENTRE);}}]}/>
            </Column>
            <div className="content">
                <Column spacing="none">
                    <Alert type="error" size="large"> Failed to load training page. Please try again later </Alert>
                </Column>
            </div>
        </Column>
    )
}

export default injectIntl(TokenValidationErrorView);