export const PLAYER_LIB_REGION_BASED_URL = {
    beta: {
        NA: "https://gamma.na.player-dolphin.last-mile.amazon.dev/",
        EU: "https://gamma.na.player-dolphin.last-mile.amazon.dev/",
        FE: "https://gamma.na.player-dolphin.last-mile.amazon.dev/"
    },
    gamma: {
        NA: "https://gamma.na.player-dolphin.last-mile.amazon.dev/",
        EU: "https://gamma.eu.player-dolphin.last-mile.amazon.dev/",
        FE: "https://gamma.fe.player-dolphin.last-mile.amazon.dev/"
    },
    prod : {
        NA: "https://prod.na.player-dolphin.last-mile.amazon.dev/",
        EU: "https://prod.eu.player-dolphin.last-mile.amazon.dev/",
        FE: "https://prod.fe.player-dolphin.last-mile.amazon.dev/"
    }
}

export const PLAYER_CONSTANTS = {
    COUNTRY: "country",
    STATION_CODE: "stationCode",
    LOCALE: "locale",
    EMPLOYEE_ID: "employeeId",
    USER_ID: "userId",
    DEFAULT: "default"
}

export const PLAYER_VERSION = "V1.0.280.0";